import NewCooperGuide from './NewCooperGuide';
import NewSpaceGuide from './NewSpaceGuide';
import { useSelector, useDispatch } from 'react-redux';

function NoviceGuide() {
  const { renderNewSpaceGuide } = useSelector((state) => state.GlobalData);

  return <>
    {
      renderNewSpaceGuide && <NewSpaceGuide />
    }
    <NewCooperGuide />
  </>;
}

export default NoviceGuide;
