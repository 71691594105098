import { intl } from 'di18n-react';
import { useCallback, useState, useEffect } from 'react';
import classBind from 'classnames/bind';
import { Tooltip } from 'antd';
// import cls from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { getCooperRoute } from '@/routes/route-cooper';
import SpaceContent from '../SpaceContent';
import MoreSystem from '../MoreSystem';
import { openNewWindow } from '@/utils';
import { isDC } from '@/utils/index';
import Tips from '@/components/Tips';
import Logo from '../Logo';
import IconFamily from '@/components/LayoutCooper/IconFamily';
import navClick from '../utils';
import stylesCommon from '../style.module.less';
import { entryEnhance, isDiDiTenant } from '@/utils/entryEnhance';
import AsideLargeSkeleton from './Skeleton/AsideLargeSkeleton';
import styles from './style.module.less';

const cxB = classBind.bind(styles);
const cxC = classBind.bind(stylesCommon);

const AsideContent = () => {
  const { teamId, knowledgeId } = useParams();
  const { pathname } = useLocation();
  const { knowledgeDetail } = useSelector(
    (state) => state.KnowledgeData,
  );
  const { asideTeamList, toastPersonTrashTip, userViewData } = useSelector((state) => state.GlobalData);
  const { setToastPersonTrashTip, setUserViewDataRq } = useDispatch().GlobalData;
  const spaceId = teamId
    ?? (knowledgeDetail.metisSpaceId == knowledgeId
      ? knowledgeDetail.belongSpaceId
      : null);

  const judgeSpaceKey = () => {
    if (spaceId) {
      const leftItem = asideTeamList.find((team) => team.id == spaceId);
      if (!leftItem) {
        return 'teamSpace';
      }

      return leftItem.id;
    }
  };

  const activeKey = useCallback(
    (item) => {
      const { path, key } = item;
      if (pathname.includes('/tome') || pathname.includes('/fromme')) return 'share';
      if (path === pathname) return key;
      if (pathname.includes('/team-file')) return judgeSpaceKey();
      if (pathname.includes('/files/')) return 'personSpace';
      if (pathname === '/knowledge') return 'knowledge';
      return '';
    },
    [pathname, asideTeamList, Number(spaceId)],
  );

  return (
    <ul className={cxB('aside-large')}>
      {getCooperRoute().asideRoute.map((item) => {
        // console.log('activeKey--', activeKey(item));

        if (item.name() === intl.t('团队空间')) {
          return (
            <React.Fragment key={item.path}>
              <li
                className={`${cxC('item-name')} ${cxB('item-name')}`}
                key={item.path}
                onClick={() => navClick(item)}
              >
                <NavLink
                  end
                  key={item.path}
                  to={item.path}
                  className={`${cxB('item-name-normal')} ${cxC(
                    'item-name-normal',
                    {
                      'item-name-active': item.key === activeKey(item),
                    },
                  )}`}
                >
                  {({ isActive }) => {
                    return (
                      <div className={cxC('item-container')}>
                        <i
                          className={cxC(
                            'dk-iconfont',
                            'aside-icon',
                            isActive || item.key === activeKey(item)
                              ? item.iconSelected
                              : item.icon,
                          )}
                        />
                        <div className={cxC('item-text')}>{item.name()}</div>
                      </div>
                    );
                  }}
                </NavLink>
              </li>
              <SpaceContent
                OverlayClassName={cxB('pine-space')}
                activeKey={activeKey(item)}
              />
              <div className={cxB('split-line')} />
            </React.Fragment>
          );
        }
        if (item.path === '/dk') {
          return <li
            className={`${cxC('item-name')} ${cxB('item-name')}`}
            key={item.path}
            onClick={() => navClick(item)}>
            <div
              key={item.path}
              onClick={() => {
                if (process.env.NODE_ENV === 'development') {
                  let local = 'http://localhost:4002/knowledge'
                  openNewWindow(local)
                } else {
                  let local = window.location.origin;
                  openNewWindow(`${local}/knowledge`)
                }
              }}
              className={`${cxB('item-name-normal')} ${cxC('item-name-normal')}`}
            >
              <div className={cxC('item-container')}>
                <i
                  className={cxC(
                    'dk-iconfont',
                    'aside-icon',
                    item.icon,
                  )}
                />
                <div className={cxC('item-text')}>{item.name()}</div>
              </div>
            </div>
          </li>
        }
        if (item.path === '/trash') {
          return (

            <li
              className={`${cxC('item-name')} ${cxB('item-name')}`}
              key={item.path}
              onClick={() => { navClick(item) }}
            >
              <NavLink
                end
                key={item.path}
                to={item.path}
                className={`${cxB('item-name-normal')} ${cxC('item-name-normal', {
                  'item-name-active': item.key === activeKey(item),
                })}`}
              >
                {({ isActive }) => {
                  return (
                    <Tooltip
                      title={() => <Tips
                        overlayClassName='blue-tip'
                        text={'被删除的内容已进入"回收站"，30天内你可以在这里找回。'}
                        isShow={true}
                        onClose={() => {
                          setToastPersonTrashTip(false);
                          let data = { ...userViewData };
                          data.New_Trash_Person_Close = '-1';
                          if (JSON.stringify(userViewData) === '{}') return;
                          setUserViewDataRq(data);
                        }}
                      />}
                      color='#047FFE'
                      placement="right"
                      visible={toastPersonTrashTip}
                      arrowPointAtCenter={true}
                      overlayClassName={'new-functions-tooltip'}
                    >
                      <div className={cxC('item-container')}>
                        <i
                          className={cxC(
                            'dk-iconfont',
                            'aside-icon',
                            isActive || item.key === activeKey(item)
                              ? item.iconSelected
                              : item.icon,
                          )}
                        />
                        <div className={cxC('item-text')}>{item.name()}</div>
                      </div>
                    </Tooltip>
                  );
                }}
              </NavLink>
            </li>

          );
        }
        return (
          <li
            className={`${cxC('item-name')} ${cxB('item-name')}`}
            key={item.path}
            onClick={() => { navClick(item) }}
          >
            <NavLink
              end
              key={item.path}
              to={item.path}
              className={`${cxB('item-name-normal')} ${cxC('item-name-normal', {
                'item-name-active': item.key === activeKey(item),
              })}`}
            >
              {({ isActive }) => {
                return (
                  <div className={cxC('item-container')}>
                    <i
                      className={cxC(
                        'dk-iconfont',
                        'aside-icon',
                        isActive || item.key === activeKey(item)
                          ? item.iconSelected
                          : item.icon,
                      )}
                    />
                    <div className={cxC('item-text')}>{item.name()}</div>
                  </div>
                );
              }}
            </NavLink>
            {/* {item.name() === intl.t('首页') && (
          <div className={cxB('split-line')} />
        )} */}
          </li>
        );
      })}
      {
        entryEnhance(
          <MoreSystem>
            {
              <li
                className={`${cxC('item-name')} ${cxB('item-name')}`}
                key={'more'}>
                <div
                  className={`${cxC('item-name-normal')} ${cxB(
                    'item-name-normal',
                  )}`}
              >
                  <div className={cxC('item-container')}>
                    <i
                      className={cxC(
                        'dk-iconfont',
                        'aside-icon',
                        'dk-icon-gengduoxitong',
                        'more-sys-icon',
                      )}
                  />
                    <div className={cxC('item-text')}>{intl.t('更多系统')}</div>
                  </div>
                </div>
              </li>
          }
          </MoreSystem>,
        )
      }
    </ul>
  )
}

function AsideLarge({ overlayClassName }) {
  const { teamId } = useParams();
  const { needAsideSkeleton } = useSelector((state) => state.GlobalData)
  const dispatch = useDispatch();
  const { getAsideTeamList, setNeedAsideSkeleton } = dispatch.GlobalData;
  const [isGettingAsideTeamList, setIsGettingAsideTeamList] = useState(false);

  useEffect(() => {
    const localGetAsideTeamList = async () => {
      setIsGettingAsideTeamList(true);
      await getAsideTeamList();
      setIsGettingAsideTeamList(false);
      if (needAsideSkeleton) {
        setNeedAsideSkeleton(false);
      }
    };
    localGetAsideTeamList()
  }, [teamId])

  return (
    <div className={`${cxC('aside', 'aside-large-wrap')} ${cxB('large-wrap', overlayClassName)}`} >
      {!isDC() && <Logo isLarge={true} />}
      <div className={cxC('aside-content', { 'aside-content-dc': isDC() })}>
        {needAsideSkeleton && isGettingAsideTeamList && <AsideLargeSkeleton />}
        {needAsideSkeleton && !isGettingAsideTeamList && <AsideContent />}
        {!needAsideSkeleton && <AsideContent />}
      </div>
      <div
        className={cxC({
          'left-action': true,
          'left-action-tenant': !isDiDiTenant(),
        })}
      >
        <IconFamily isLarge={true} />
      </div>
    </div>
  );
}

export default AsideLarge;
