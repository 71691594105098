import { intl } from 'di18n-react';
import { collaborativeDocumentTypes } from '@/constants/cooper';
import { isDiDiTenant } from '@/utils/entryEnhance';

export const handleDocumentType = (documentType) => {
  switch (documentType) {
    case 6:
      return 'sheet';

    case 7:
      return 'document';

    case 8:
      return 'slide';

    case 13:
      return 'document';

    case 14:
      return 'sheet';

    case 15:
      return 'slide';

    default:
      return '';
  }
};
export const getDocTypeName = (type) => {
  switch (type) {
    case collaborativeDocumentTypes.anonymousWord:
      return intl.t('匿名文档');

    case collaborativeDocumentTypes.word:
      return intl.t('协作文档');

    case collaborativeDocumentTypes.excel:
      return intl.t('协作表格');

    case collaborativeDocumentTypes.slide:
      return intl.t('协作PPT');

    case collaborativeDocumentTypes.didoc:
      return intl.t('氢文档');

    default:
      return '';
  }
};
export const handleUrl = (item) => {
  const type = item.messageType;
  const {
    teamId,
    teamUrl,
    documentType,
    resourceType,
    documentId,
    docUrl,
    docLink,
    trashUrl,
    spaceId,
    resourceId,
    pcLink
    // teamType,
  } = item.mergeFields;

  switch (type) {
    case 'CooperationApplication':
    case 'CooperationApplicationAccepted':
    case 'CooperationApplicationRejected':
    case 'DocOwnerApplyCompleteMessage':
    case 'DocOwnerApplyAppliedMessage':
    case 'CooperationApplicationNoApproverMessage':
    case 'CooperationInvitation':
      return resourceType === 2 || resourceType === 13 || resourceType === 14 || resourceType === 15
        ? isDiDiTenant() ? `/docs/${handleDocumentType(documentType)}/${documentId || resourceId}` : `/docs2/${handleDocumentType(documentType)}/${documentId || resourceId}`
        : `/didoc/file/${documentId}`;

    case 'SpaceApplication':
    case 'SpaceApplicationAccepted':
    case 'SpaceQuotaAgreed':
      return `/team-file/${teamId}`;
      // return teamType === 'team' ? `/team-file/${teamId}` : '/disk';

    case 'SpaceQuotaRejected':
      return `/team-file/${teamId}`;
      // return teamType === 'team' ? `/team-file/${teamId}` : '/disk';
    case 'SpaceOwnerApplyCompleteMessage':
      return `/team-file/${spaceId}`;

    case 'SpaceApplicationRejected':
      return `/team-file/${teamId}`;

    case 'DirectShareCreated':
      return '/tome';

    case 'DocumentComment':
    case 'ThumbsUpCreated':
      return docLink;

    case 'SpaceMemberInvited':
    case 'SpaceTransferred':
      return teamUrl;

    case 'SpaceDeleted':
      return trashUrl;

    case 'DocumentAtNotice':
      return docUrl;

    case 'DocumentOwnerApplyDeleteMessage':
      return pcLink;
    
    case 'DocumentOwnerApplyNotFoundApproveMessage':
      return pcLink;
    default:
      return '';
  }
};

export function formatNumber(num) {
  return num < 100 ? num : '99+';
}
