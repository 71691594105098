/* eslint-disable no-constant-condition */
import { intl, getLocale } from 'di18n-react';
import React from 'react';
import { Checkbox, Input } from 'antd';
import { handleUrl } from './utils';

const { TextArea } = Input;

// DocumentOwnerApplyDeleteMessage  站内信文档被删除通知申请人
export function renderDocumentOwnerApplyDeleteMessage(item, index) {
  return (
    <div className="notify-list-item-detail-desc">
      {intl.t('针对你提交的“成为协作文档')}
      <a
        className="notify-list-item-active"
        title={item.mergeFields.docName}
        href={handleUrl(item)}
        target="_blank"
        onClick={(e) => {
          // sendEvent('MSG_CENTER_CLICK_FILE_NAME');
          this.onClickUnhandled.call(this, item, index, e);
        }}
      >
        {item.mergeFields.docName}
      </a>
      {intl.t('所有者“的申请，鉴于协作文档被删除，该申请已失效。')}
    </div>
  );
}
// renderSpaceOwnerApplyDeleteMessage
export function renderSpaceOwnerApplyDeleteMessage(item, index) {
  return (
    <div className="notify-list-item-detail-desc">
      {intl.t('针对你提交的“成为团队空间')}
      &nbsp;
      <a
        className="notify-list-item-active"
        title={item.mergeFields.spaceName}
        href={item.mergeFields.pcLink}
        target="_blank"
        onClick={(e) => {
          // sendEvent('MSG_CENTER_CLICK_FILE_NAME');
          this.onClickUnhandled.call(this, item, index, e);
        }}
      >
        {item.mergeFields.spaceName}
      </a>
      {intl.t('所有者“的申请，鉴于空间被删除，该申请已失效。')}
    </div>
  );
}

// DocumentOwnerApplyNotFoundApproveMessage  站内信审批人离职找不到审批者
export function renderCooperationApplyNoPerson(item, index) {
  return (
    <div className="notify-list-item-detail-desc">
      {intl.t('针对你提交的“成为协作文档')}
      <a
        className="notify-list-item-active"
        title={item.mergeFields.docName}
        href={handleUrl(item)}
        target="_blank"
        onClick={(e) => {
          // sendEvent('MSG_CENTER_CLICK_FILE_NAME');
          this.onClickUnhandled.call(this, item, index, e);
        }}
      >
        {item.mergeFields.docName}
      </a>
      {intl.t('所有者“的申请，鉴于目前审批人{name}已离职或不具备审批权限，该申请已失效。暂无审批人可审批你提交的文档所有权申请，可联系相关滴滴员工提交申请。', { name: item?.mergeFields?.approveName })}
    </div>
  );
}


// 申请协作文档权限
export function renderCooperationApplication(item, index) {
  function onCheckboxChange(type, status) {
    const { handleType, unhandledList, handledList } = this.state;
    let list = handleType === 0 ? unhandledList : handledList;
    let listType = handleType === 0 ? 'unhandledList' : 'handledList';
    list[index].mergeFields[type] = status;
    this.setState({
      [listType]: list,
    });
  }

  const Title = (
    <a
      className="notify-list-item-active"
      title={item.mergeFields.documentName}
      href={handleUrl(item)}
      target="_blank"
      onClick={(e) => {
        // sendEvent('MSG_CENTER_CLICK_FILE_NAME');
        this.onClickUnhandled.call(this, item, index, e);
      }}
    >
      {item.mergeFields.documentName}
    </a>
  );

  function renderBackground(approverType = 'DOC_OWNER') {
    switch (approverType) {
      // 空间所有者
      case 'SPACE_OWNER':
        return <div className='notice-bg-tips'>
          <span className='bold'>{intl.t('背景说明：')}</span>
          <div className="notify-list-item-detail-desc">
            {intl.t('协作文档')}
            &nbsp;
            <a
              className="notify-list-item-active"
              title={item.mergeFields.documentName}
              href={item.mergeFields.pcLink}
              target="_blank"
              onClick={(e) => {
                this.onClickUnhandled.call(this, item, index, e);
              }}
            >
              {item.mergeFields.documentName}
            </a>
            {intl.t('归属于团队空间')}
            <a
              className="notify-list-item-active"
              title={item.mergeFields.spaceName}
              href={item.mergeFields.spaceLink}
              target="_blank"
              onClick={(e) => {
                // sendEvent('MSG_CENTER_CLICK_TEAM_NAME');
                this.onClickUnhandled.call(this, item, index, e);
              }}
            >
              {item.mergeFields.spaceName}
            </a>
            {intl.t('，文档所有者')}
            {item?.mergeFields?.docOwnerName}
            {intl.t('离职时未做所有权交接，现有用户申请该文档访问权限，请评估是否通过该申请')}
          </div>
        </div>

      // 空间所有者上级
      case 'SPACE_OWNER_SUPERIOR':
        return <div className='notice-bg-tips'>
          <span className='bold'>{intl.t('背景说明：')}</span>
          <div className="notify-list-item-detail-desc">
            {intl.t('协作文档')}
            &nbsp;
            <a
              className="notify-list-item-active"
              title={item.mergeFields.documentName}
              href={item.mergeFields.pcLink}
              target="_blank"
              onClick={(e) => {
                this.onClickUnhandled.call(this, item, index, e);
              }}
            >
              {item.mergeFields.documentName}
            </a>
            {intl.t('所在团队空间')}
            <a
              className="notify-list-item-active"
              title={item.mergeFields.spaceName}
              href={item.mergeFields.spaceLink}
              target="_blank"
              onClick={(e) => {
                // sendEvent('MSG_CENTER_CLICK_TEAM_NAME');
                this.onClickUnhandled.call(this, item, index, e);
              }}
            >
              {item.mergeFields.spaceName}
            </a>
            {intl.t('为你曾经的下属员工')}
            {item?.mergeFields?.docOwnerName}
            {intl.t('创建，该员工离职时未做所有权交接，现有用户申请该文档的访问权限，应信息安全要求，需要你评估是否通过该申请')}
          </div>
        </div>

      // 申请人上级
      case 'SUPERIOR_OF_APPLICANT':
        return <div className='notice-bg-tips'>
        <span className='bold'>{intl.t('背景说明：')}</span>
        <div className="notify-list-item-detail-desc">
          {intl.t('协作文档')}
          &nbsp;
          <a
            className="notify-list-item-active"
            title={item.mergeFields.documentName}
            href={item.mergeFields.pcLink}
            target="_blank"
            onClick={(e) => {
              this.onClickUnhandled.call(this, item, index, e);
            }}
          >
            {item.mergeFields.documentName}
          </a>
          {intl.t('所有者')}
          {item?.mergeFields?.docOwnerName}
          {intl.t('已离职，无法审批该文档的访问权限申请，你的下属员工期望获得该文档的访问权限，应信息安全要求，需要你评估是否通过该申请')}
        </div>
      </div>

       // T1管理者
       case 'T1_OF_APPLICANT':
        return <div className='notice-bg-tips'>
        <span className='bold'>{intl.t('背景说明：')}</span>
        <div className="notify-list-item-detail-desc">
          {intl.t('协作文档')}
          &nbsp;
          <a
            className="notify-list-item-active"
            title={item.mergeFields.documentName}
            href={item.mergeFields.pcLink}
            target="_blank"
            onClick={(e) => {
              this.onClickUnhandled.call(this, item, index, e);
            }}
          >
            {item.mergeFields.documentName}
          </a>
          {intl.t('所有者')}
          {item?.mergeFields?.docOwnerName}
          {intl.t('已离职，无法审批该文档的访问权限申请，你的下属员工期望获得该文档的访问权限，应信息安全要求，需要你评估是否通过该申请')}
        </div>
      </div>

    
      default:
        break;
    }
  }

  return [
    <div
      className="notify-list-item-detail-desc"
      key="0">
      <span>
        {intl.t('申请协作文档权限')}
        {Title}
        {intl.t('协作文档权限')}
      </span>

      {!!item.mergeFields.reason && (
        <span>
          {' '}
          {intl.t('，申请理由：')}
          {item.mergeFields.reason}
        </span>
      )}
    </div>,

    <div
      className="notify-list-item-access-setting"
      key="1"
      onClick={(e) => e.stopPropagation()}
    >
      <span>{intl.t('权限设置：')}</span>
      <Checkbox
        defaultChecked={true}
        disabled={true}
        onChange={(e) => {
          onCheckboxChange.bind(this)('view', e.target.checked);
        }}
      >
        {intl.t('查看')}
      </Checkbox>
      {item.mergeFields.resourceType !== 4 && (
        <Checkbox
          checked={item.mergeFields.write}
          disabled={item.done}
          onChange={(e) => {
            onCheckboxChange.bind(this)('write', e.target.checked);
          }}
        >
          {intl.t('编辑')}
        </Checkbox>
      )}
      {(item.mergeFields.resourceType === 2 || item.mergeFields.resourceType === 13 || item.mergeFields.resourceType === 14) &&  ( // 仅协作文档有下载权限
        <Checkbox
          checked={item.mergeFields.download}
          disabled={item.done}
          onChange={(e) => {
            onCheckboxChange.bind(this)('download', e.target.checked);
          }}
        >
          {intl.t('下载')}
        </Checkbox>
      )}
    </div>,
    <div>{ renderBackground(item?.mergeFields?.approverType)}</div>
  ];
}

// 申请加入团队
export function renderTeamApplication(item, index) {
  return (
    <div className="notify-list-item-detail-desc">
      <span>
        {intl.t('申请团队空间权限')}
        <a
          className="notify-list-item-active"
          title={item.mergeFields.teamName}
          href={`/team-file/${item.mergeFields.teamId}`}
          target="_blank"
          onClick={(e) => {
            // sendEvent('MSG_CENTER_CLICK_TEAM_NAME');
            this.onClickUnhandled.call(this, item, index, e);
          }}
        >
          {item.mergeFields.teamName}
        </a>
        {intl.t('团队空间权限')}
      </span>

      {item.mergeFields.reason ? (
        <span>
          {intl.t('，申请理由：')}
          {item.mergeFields.reason}
        </span>
      ) : null}
      {item.mergeFields.permissionsCn || item.mergeFields.permissionsEn ? (
        <span>
          {intl.t('，赋予权限：')}
          {getLocale() === 'zh-CN' ? item.mergeFields.permissionsCn : item.mergeFields.permissionsEn}
        </span>
      ) : null}
    </div>
  );
}

// renderCooperationApplicationNoApproverMessage
export function renderCooperationApplicationNoApproverMessage(item, index) {
  return (
    <div className="notify-list-item-detail-desc">
      {intl.t('暂无审批人可审批你提交的【')}
      &nbsp;
      <a
        className="notify-list-item-active"
        title={item.mergeFields.documentName}
        href={handleUrl(item)}
        target="_blank"
        onClick={(e) => {
          // sendEvent('MSG_CENTER_CLICK_FILE_NAME');
          this.onClickUnhandled.call(this, item, index, e);
        }}
      >
        {item.mergeFields.documentName}
      </a>
      {intl.t('】文档访问权限申请，可联系相关滴滴员工获取该文档的管理权限后邀请你加入该文档的协作')}
    </div>
  );
}

// 邀请协作文档
export function renderCooperationInvitation(item, index) {
  return (
    <div className="notify-list-item-detail-desc">
      {intl.t('邀请您一起协作')}
      &nbsp;
      <a
        className="notify-list-item-active"
        title={item.mergeFields.documentName}
        href={handleUrl(item)}
        target="_blank"
        onClick={(e) => {
          // sendEvent('MSG_CENTER_CLICK_FILE_NAME');
          this.onClickUnhandled.call(this, item, index, e);
        }}
      >
        {item.mergeFields.documentName}
      </a>
    </div>
  );
}

// 离职转交
export function renderResignDeliver(item, index) {
  const { mergeFields } = item;

  return (
    <div className="notify-list-item-detail-desc">
      {
        intl.t('{solt0}将{solt1}的文件转交给您，共计：', {
          solt0: getLocale() === 'zh-CN' ? mergeFields.senderNameCn : mergeFields.senderNameEn,
          solt1: mergeFields.transferName,
        })
      }
      <span style={{ marginLeft: '4px' }}>
        {
          mergeFields.cooperCount && intl.t('{slot0}个团队空间', {
            slot0: mergeFields.cooperCount,
          })
        }
      </span>
      <span style={{ marginLeft: '4px' }}>
        {
          mergeFields.knowledgeCount && intl.t('{slot0}个知识库', {
            slot0: mergeFields.knowledgeCount,
          })
        }
      </span>

    </div>
  );
}

// 通过申请
export function renderCooperationApplicationAccepted(item, index, type) {
  const {
    teamName,
    documentName,
    permission,
    permissionEn,
    pcContentSourceUrl,
    permissionsCn,
    permissionsEn,
  } = item.mergeFields;
  const name = type === 'team' ? teamName : documentName;
  const url = type === 'team' ? pcContentSourceUrl : handleUrl(item);
  const title = (
    <a
      className="notify-list-item-active"
      title={name}
      href={url}
      target="_blank"
      onClick={(e) => {
        // sendEvent('MSG_CENTER_CLICK_TEAM_NAME');
        this.onClickUnhandled.call(this, item, index, e);
      }}
    >
      {name}
    </a>
  );

  return (
    <div className="notify-list-item-detail-desc">
      {type === 'team' ? (
        <span>
          {intl.t('您已成功加入了')}
          {title}
          {intl.t('团队空间1')}
          {
            permissionsEn || permissionsCn ? (
              <span>
                {intl.t('，赋予权限：')}
                {getLocale() === 'zh-CN' ? permissionsCn : permissionsEn}
              </span>
            ) : null
          }
        </span>
      ) : (
        <span>
          {intl.t('您已成功加入了')}
          {title}
          {intl.t('协作文档，为您开通了：{permission}权限', {
            permission: getLocale() === 'zh-CN' ? permission : permissionEn,
          })}
        </span>
      )}
    </div>
  );
}


// 未通过申请
export function renderCooperationApplicationRejected(item, index, type) {
  const { teamName, permissionsEn, permissionsCn, status } = item.mergeFields;
  const name = type === 'team' ? teamName : item.mergeFields.documentName;
  const title = (
    <a
      className="notify-list-item-active"
      title={name}
      href={handleUrl(item)}
      target="_blank"
      onClick={(e) => {
        // sendEvent('MSG_CENTER_CLICK_TEAM_NAME');
        this.onClickUnhandled.call(this, item, index, e);
      }}
    >
      {name}
    </a>
  );
  // 申请的内容被删除
  if (status === 1) {
    return (
      <div className="notify-list-item-detail-desc">
        {type === 'team' ? (
          <span>
            {intl.t('该空间已被删除，您对')}
            {title}
            {intl.t('团队空间的权限申请已失效')}
          </span>
        ) : (
          <span>
            {intl.t('该协作文档已被删除，您对')}
            {title}
            {intl.t('协作文档权限申请已失效')}
          </span>
        )}
      </div>
    );
  }
  return (
    <div className="notify-list-item-detail-desc">
      {type === 'team' ? (
        <span>
          {intl.t('您未通过')}
          {title}
          {intl.t('团队空间权限申请')}
          {
            permissionsEn || permissionsCn ? (
              <span>
                {intl.t('，赋予权限：')}
                {getLocale() === 'zh-CN' ? permissionsCn : permissionsEn}
              </span>
            ) : null
          }
        </span>
      ) : (
        <span>
          {intl.t('您未通过')}
          {title}
          {intl.t('协作文档权限申请')}
        </span>
      )}
    </div>
  );
}

// 通过扩容申请
export function renderQuotaApplicationAccepted(item, index) {
  const { teamType, teamName, oldQuota: one, newQuota: two } = item.mergeFields;
  const name = teamType === 'team' ? teamName : intl.t('个人空间');
  const title = (
    <a
      className="notify-list-item-active"
      title={name}
      href={handleUrl(item)}
      target="_blank"
      onClick={(e) => {
        // sendEvent('MSG_CENTER_CLICK_TEAM_NAME');
        this.onClickUnhandled.call(this, item, index, e);
      }}
    >
      {name}
    </a>
  );

  return (
    <div className="notify-list-item-detail-desc">
      {teamType === 'team' ? (
        <span>
          {intl.t('您所申请的团队空间')}
          {title}
          {intl.t('扩容已经通过审核，容量已由{one}G扩容至{two}G', {
            one,
            two,
          })}
        </span>
      ) : (
        <span>
          {intl.t('您所申请的')}
          {title}
          {intl.t('扩容已经通过审核，容量已由{one}G扩容至{two}G', {
            one,
            two,
          })}
        </span>
      )}
    </div>
  );
}

// 未通过扩容申请
export function renderQuotaApplicationRejected(item, index) {
  const { teamType, teamName } = item.mergeFields;
  const name = teamType === 'team' ? teamName : intl.t('个人空间');
  const title = (
    <a
      className="notify-list-item-active"
      title={name}
      href={handleUrl(item)}
      target="_blank"
      onClick={(e) => {
        // sendEvent('MSG_CENTER_CLICK_TEAM_NAME');
        this.onClickUnhandled.call(this, item, index, e);
      }}
    >
      {name}
    </a>
  );

  return (
    <div className="notify-list-item-detail-desc">
      {teamType === 'team' ? (
        <span>
          {intl.t('您所申请的团队空间 {team} 扩容未通过审核', { team: title })}
        </span>
      ) : (
        <span>
          {intl.t('您所申请的 {personal} 扩容未通过审核', { personal: title })}
        </span>
      )}
    </div>
  );
}

// 按钮
export function renderTodo(item, index, bySelf) {
  let statusText = '';
  if (item.mergeFields.invalid) {
    statusText = intl.t('已失效');
  } else if (bySelf) {
    statusText = item.mergeFields.accepted
      ? intl.t('已同意')
      : intl.t('已拒绝');
  } else {
    statusText = item.mergeFields.accepted
      ? intl.t('已由其他管理员同意')
      : intl.t('已由其他管理员拒绝');
  }


  return (
    <div className="notify-list-item-btns">
      {!item.done ? (
        [
          <a
            key="0"
            href="#"
            className="notify-list-item-reject"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.handleTodoBtn(item, false, index);
            }}
          >
            {intl.t('拒绝')}
          </a>,
          <a
            key="1"
            href="#"
            className="notify-list-item-accept"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.handleTodoBtn(item, true, index);
            }}
          >
            {intl.t('同意')}
          </a>,
        ]
      ) : (
        <span className="notify-list-item-status">{statusText}</span>
      )}
    </div>
  );
}

// 申请团队空间所有权
export function renderSpaceOwnerApplication(item, index) {
  return (
    <div className="notify-list-item-detail-desc">
      <div className='title-con'>
        <span>
          {intl.t('申请成为团队空间')}
          <a
            className="notify-list-item-active"
            title={item?.mergeFields?.spaceName}
            href={`/team-file/${item?.mergeFields?.spaceId}`}
            target="_blank"
            onClick={(e) => {
              // sendEvent('MSG_CENTER_CLICK_TEAM_NAME');
              this.onClickUnhandled.call(this, item, index, e);
            }}
          >
            {item?.mergeFields?.spaceName}
          </a>
          {intl.t('的所有者')}
        </span>

        {item?.mergeFields?.applicantMessage ? (
          <span>
            {intl.t('，申请理由：')}
            {item?.mergeFields?.applicantMessage }
          </span>
        ) : null}
      </div>
      {
        !item.done && <TextArea
          className='notify-list-item-detail-text-area'
          placeholder={intl.t('请填写审批理由，限制200字（选填)')}
          maxLength={200}
          showCount={true}
          autoSize={{ minRows: 3, maxRows: 3 }}
          bordered={false}
          onChange={(e) => {
            e.stopPropagation()
            e.preventDefault()
            item.mergeFields.approvalReason = e.target.value
          }}
          />
      }
      {
        item.done && item?.mergeFields?.approvalMessage && <div className='notify-list-item-detail-desc-approval-message'>
          {intl.t('审批理由：')}
          {item?.mergeFields?.approvalMessage }
        </div>
      }
    </div>
  );
}

// 申请成为文档所有权
export function renderDocOwnerApplication(item, index) {
  return (
    <div className="notify-list-item-detail-desc">
      <div className='title-con'>
        <span>
          {intl.t('申请成为协作文档')}
          <a
            className="notify-list-item-active"
            title={item?.mergeFields?.resourceName}
            href={handleUrl(item)}
            target="_blank"
            onClick={(e) => {
              // sendEvent('MSG_CENTER_CLICK_TEAM_NAME');
              this.onClickUnhandled.call(this, item, index, e);
            }}
          >
            {item?.mergeFields?.resourceName}
          </a>
          {/* {
            (item?.mergeFields?.relationTypeTags || []).includes('IN_OUT') && (
              <span>（{intl.t('外部')}）</span>
            )
          } */}
          {intl.t('的所有者')}
        </span>

        {item?.mergeFields?.applicantMessage ? (
          <span>
            {intl.t('，申请理由：')}
            {item?.mergeFields?.applicantMessage }
          </span>
        ) : null}
      </div>
      {
        !item.done && <TextArea
          className='notify-list-item-detail-text-area'
          placeholder={intl.t('请填写审批理由，限制200字（选填)')}
          maxLength={200}
          showCount={true}
          autoSize={{ minRows: 3, maxRows: 3 }}
          bordered={false}
          onChange={(e) => {
            e.stopPropagation()
            e.preventDefault()
            item.mergeFields.approvalReason = e.target.value
          }}
          />
      }
      {
        item.done && item.mergeFields.approvalMessage && <div className='notify-list-item-detail-desc-approval-message'>
          {intl.t('审批理由：')}
          {item?.mergeFields?.approvalMessage }
        </div>
      }
    </div>
  );
}

// 申请更高文档权限 申请已发送
export function renderDocPermApplySend(item, index) {
  return (
    <div className="notify-list-item-detail-desc">
      <div className='title-con'>
        <span>
          {
            intl.t('您已提交协作文档{slot0}{slot1}权限的申请，由{slot2}进行审批，请耐心等待', {
              slot0: <a
                className="notify-list-item-active"
                href={item?.mergeFields?.docUrl}
                target="_blank"
                onClick={(e) => {
                  this.onClickUnhandled.call(this, item, index, e);
                }}
            >
                {item?.mergeFields?.docName}
              </a>,
              slot1: getLocale() === 'zh-CN' ? item?.mergeFields?.permNameCn : item?.mergeFields?.permNameEn,
              slot2: <a
                className="notify-list-item-active"
                style={{ color: '#127FF0' }}
                href={`dchat://im/start_conversation?name=${item?.from?.ldap}`}
              >
                {getLocale() === 'zh-CN' ? item?.from?.name?.chineseName : item?.from?.name?.englishName}
              </a>,
            })
          }
        </span>
      </div>
    </div>
  );
}

// 申请更高权限 被同意/拒绝
export function renderDocPermApplyResult(item, index, success) {
  const {
    docName,
    permNameCn,
    permNameEn,
    docUrl,
  } = item.mergeFields ?? {};
  const title = (
    <a
      className="notify-list-item-active"
      title={docName}
      href={docUrl}
      target="_blank"
      onClick={(e) => {
        this.onClickUnhandled.call(this, item, index, e);
      }}
    >
      {docName}
    </a>
  );
  return (
    <div className="notify-list-item-detail-desc">
      <span>
        {intl.t('您提交的协作文档{slot0}{slot1}权限的申请已审批{slot2}', {
          slot0: title,
          slot1: getLocale() === 'zh-CN' ? permNameCn : permNameEn,
          slot2: success ? intl.t('通过') : intl.t('拒绝2'),
        })}
      </span>
    </div>
  );
}

// 申请更高权限 无审批人
export function renderDocPermApplyNoPerson(item, index, globalOutsideChain) {
  const {
    docName,
    permNameEn,
    permNameCn,
    docUrl,
  } = item?.mergeFields ?? {}
  return (
    <div className="notify-list-item-detail-desc">
      {intl.t('因审批人离职或不具备审批权限,你提交的申请协作文档{slot0}{slot1}权限申请已失效。', {
        slot0: <a
          className="notify-list-item-active"
          title={docName}
          href={docUrl}
          target="_blank"
          onClick={(e) => {
          // sendEvent('MSG_CENTER_CLICK_FILE_NAME');
            this.onClickUnhandled.call(this, item, index, e);
          }}
      >
          {docName}
        </a>,
        slot1: getLocale() === 'zh-CN' ? permNameCn : permNameEn,
      })}
      <a
        href={globalOutsideChain?.no_approver_solution}
        className="notify-list-item-active"
        target="_blank"
      >{intl.t('可点击查看其他解决方案')}</a>
    </div>
  );
}

// 申请更高权限 审批人进行审批
export function renderDocPermToApproved(item, index) {
  const Title = (
    <a
      className="notify-list-item-active"
      title={item?.mergeFields?.docName}
      href={item?.mergeFields?.docUrl}
      target="_blank"
      onClick={(e) => {
        this.onClickUnhandled.call(this, item, index, e);
      }}
    >
      {item?.mergeFields?.docName}
    </a>
  );

  return [
    <div
      className="notify-list-item-detail-desc"
      key="0">
      <span>
        {getLocale() === 'zh-CN' ? item?.from?.name?.chineseName : item?.from?.name?.englishName}
        {intl.t('申请协作文档{slot0}的{slot1}权限。', {
          slot0: Title,
          slot1: getLocale() === 'zh-CN' ? item?.mergeFields?.permNameCn : item?.mergeFields?.permNameEn,
        })}
      </span>

      {!!item?.mergeFields?.applicationMessage && (
        <p>
          {intl.t('申请理由：')}
          {item?.mergeFields?.applicationMessage}
        </p>
      )}
    </div>,
  ];
}


