import { addTask, createTask, fileIds, taskToken } from '@/service/cooper/upload';
import { Modal } from 'antd';
import { intl } from 'di18n-react';
import { compose } from 'ramda';
import { uploadSize } from './constants';
import { PLimit, getFilePath } from './util';

export const splitCreateTaskPatch = (strategyfiles) => {
  let filePatchs = {};
  for (let idx = 0; idx < strategyfiles.length; idx++) {
    if (
      Object.prototype.toString.call(filePatchs[String(Math.floor(idx / uploadSize.taskPatchsSize))]) !== '[object Array]'
    ) {
      filePatchs[String(Math.floor(idx / uploadSize.taskPatchsSize))] = [];
    }
    const defaultFragments = strategyfiles[idx].file.size === 0 ? 0 : 1;
    filePatchs[String(Math.floor(idx / uploadSize.taskPatchsSize))].push({
      strategy: strategyfiles[idx].strategy,
      resourceSize: strategyfiles[idx].file.size,
      resourcePath:
        getFilePath(strategyfiles[idx].file).indexOf('/') === 0
          ? getFilePath(strategyfiles[idx].file)
          : `/${getFilePath(strategyfiles[idx].file)}`,
      fragmentsTotal:
        strategyfiles[idx].file.size > uploadSize.sliceBoundary
          ? Math.ceil(strategyfiles[idx].file.size / uploadSize.sliceSize)
          : defaultFragments,
    });
  }
  return Object.keys(filePatchs).map((v) => filePatchs[v]);
};

const createFirstTask = ({ teamId, parentId }) => (totalFiles) => (taskPatchs) => createTask({
  resources: taskPatchs[0],
  totalFiles,
  teamId,
  parentId,
}).then(({ taskId }) => ({ taskPatchs: taskPatchs.slice(1), taskId }));

const createAddTask = ({ teamId, parentId }) => ({ taskPatchs, taskId }) => {
  const pLimit = new PLimit(2);
  return Promise.all(
    taskPatchs.map(
      (taskPatch) => pLimit.build(() => addTask({ taskPatch, taskId, teamId, parentId })),
    ),
  ).then(() => taskId);
};

const intervalTaskToken = ({ teamId, parentId }) => (taskId) => taskToken({ taskId, teamId, parentId }).then(({ status, token, failCode = '', failMessage = '' }) => {
  // eslint-disable-line camelcase
  try {
    if (status === 0) {
      return { taskId, token };
    } if (status === 2) {
      return intervalTaskToken({ teamId, parentId })(taskId);
    } if (status === 1 && String(failCode) === '1007') {
      throw new Error('status 1');
    } else {
      throw new Error('addresses error');
    }
  } catch (error) {
    if (error.message === 'status 1') {
      Modal.error({
        title: intl.t('上传失败'),
        content: intl.t('已超过最大容量，请申请扩容后重试。'),
        okText: intl.t('知道了'),
      });
    } else if (error.message === 'addresses error') {
      Modal.error({
        title: intl.t('上传失败'),
        content: intl.t('一次性上传文件过多、过大或网络异常可能导致上传失败，建议分批上传或确认网络正常后重新上传。'),
        okText: intl.t('知道了'),
      });
    }
  }
});

// 分页获取所有文件的fileId
const composeFileIds = ({ teamId, parentId, pageNum, pageSize }) => ({
  token,
  taskId,
}) => fileIds({ teamId, parentId, taskId, pageNum, pageSize }).then((res) => {
  return {
    taskId,
    items: res,
    token,
  };
});

export const createTaskAndGetIds = ({ teamId, parentId }) => (strategyfiles) => compose(
  createFirstTask({ teamId, parentId })(strategyfiles.length),
  splitCreateTaskPatch,
)(strategyfiles)
  .then(createAddTask({ teamId, parentId }))
  .then(intervalTaskToken({ teamId, parentId }))
  .then(composeFileIds({
    teamId,
    parentId,
    pageNum: 0,
    pageSize: 500,
  }));
