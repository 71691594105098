/* eslint-disable radix */
/* eslint-disable class-methods-use-this */
import { message, Popover } from 'antd';
import { connect } from 'react-redux';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { intl } from 'di18n-react';
import React from 'react';
import { PAGE_SIZE } from '@/constants';
import onClickOutside from 'react-onclickoutside';
import api from '@/utils/request/api/CooperApi';
import { collaborativeDocumentTypes } from '@/constants/cooper';
import { get, patch, put } from '@/utils/request/cooper';
import { formatNumber } from './utils';
import './index.less';
import {
  renderSpaceOwnerApplyDeleteMessage,
  renderDocumentOwnerApplyDeleteMessage,
  renderCooperationApplyNoPerson,
  renderCooperationApplication,
  renderCooperationApplicationNoApproverMessage,
  renderCooperationApplicationAccepted,
  renderCooperationApplicationRejected, renderCooperationInvitation,
  renderQuotaApplicationAccepted,
  renderQuotaApplicationRejected, renderTeamApplication,
  renderSpaceOwnerApplication,
  renderDocOwnerApplication,
  renderTodo,
  renderResignDeliver,
  renderDocPermApplySend,
  renderDocPermApplyResult,
  renderDocPermApplyNoPerson,
  renderDocPermToApproved,
} from './items';
import {
  NoticeDirectShare,
  NoticeDocRefer, NoticeDocumentComment, NoticeCommentRefer,
  NoticeDocumentThumbsup, NoticeDocumentTransferred,
  NoticeDocumentTransferredOwnerNotice,
  NoticeTeamDeletion, NoticeTeamDelivery, NoticeTeamInvitation, PageAtNotice,
  ApplyOutSpaceReject, ApplyOutSpaceAgree,
  DocOwnerApplyCompleteMessage,
  SpaceOwnerApplyCompleteMessage,
} from './notices';
import { getSign } from '@didi/wsgsig';
import { post } from '@/utils/request';
import Tag from '@/components/Tag';

// 站内信
class CooperNotify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      // 当前用户id
      uid: 0,
      unhandledList: [],
      handledList: [],
      handleType: 0,
      // 0:未处理  1:已处理
      loading: false,
      todoCount: 0,
      isInitLoading: true,
    };

    this.messageStatus = {
      unread: {
        pageNum: -1,
        pageSize: PAGE_SIZE,
        loadedEnd: false,
      },

      read: {
        pageNum: -1,
        pageSize: PAGE_SIZE,
        loadedEnd: false,
      },
    };

    this.ListBodyHeight = 0;
    this.scrollEndHeight = 0;
    this.loading = false;
    this.isOpen = true;
    this.latestTs = new Date().getTime();
    this.scrollToLoad = this.scrollToLoad.bind(this);
  }

  // componentDidMount() {
  //   // this.computeListBodySize();
  //   // this.computeListItemsSize();
  //   this.loadUnhandledList(1);
  //   this.loadHandledList(1);
  //   // this.ListBody.addEventListener('scroll', this.scrollToLoad);
  // }

  // componentDidUpdate() {
  //   this.computeListItemsSize();
  // }

  componentDidMount() {
    this.setState({ todoCount: this.props.unreadCount });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.unreadCount !== this.props.unreadCount) {
      this.setState({ todoCount: this.props.unreadCount });
    }
  }

  computeListItemsSize() {
    if (this.ListItems) {
      const ListItemsHeight = parseInt(getComputedStyle(this.ListItems).height) || 0;
      this.scrollEndHeight = ListItemsHeight - this.ListBodyHeight;
    }
  }

  computeListBodySize() {
    if (this.ListBody) {
      const styles = getComputedStyle(this.ListBody);
      const height = parseInt(styles.height) || 0;
      const borderTopWidth = parseInt(styles.borderTopWidth) || 0;
      this.ListBodyHeight = height - borderTopWidth;
    }
  }

  scrollToLoad() {
    const loadedEnd = this.state.handleType === 0
      ? this.messageStatus.unread.loadedEnd
      : this.messageStatus.read.loadedEnd;

    if (this.loading || loadedEnd) {
      return;
    }

    const { scrollTop } = this.ListBody;

    if (this.ListBody) {
      const styles = getComputedStyle(this.ListBody);
      const height = parseInt(styles.height) || 0;
      const borderTopWidth = parseInt(styles.borderTopWidth) || 0;
      this.ListBodyHeight = height - borderTopWidth;
    }

    if (this.ListItems) {
      const ListItemsHeight = parseInt(getComputedStyle(this.ListItems).height) || 0;
      this.scrollEndHeight = ListItemsHeight - this.ListBodyHeight;
    }

    if (scrollTop >= this.scrollEndHeight && !this.state.loading) {
      this.setState({
        loading: true,
      });

      this.loadList();
    }
  }

  deDuplication(oldArr, newArr) {
    return newArr.filter((v) => !oldArr.some((j) => j.id === v.id));
  }

  loadUnhandledList = (loadType = 0, persistExists = true) => {
    if (this.loading) {
      return;
    }

    this.loading = true;
    let params = {
      pageNum: this.messageStatus.unread.pageNum + 1,
      pageSize: this.messageStatus.unread.pageSize,
    };

    let ngis = getSign({
      params,
    });

    get(api.API_NOTIFICATIONS_UNREAD, {
      params: {
        pageNum: this.messageStatus.unread.pageNum + 1,
        pageSize: this.messageStatus.unread.pageSize,
      },
      headers: {
        wsgsig: ngis,
      },
    }).then((data = {}) => {
      if (!this.isOpen) {
        return;
      }

      const list = data.items || [];
      let unhandledList = persistExists ? this.state.unhandledList : [];

      if (!list.length) {
        this.messageStatus.unread.loadedEnd = true;
        this.loading = false;
        this.setState({
          loading: false,
          isInitLoading: false,
          unhandledList,
          todoCount: data.totalCount,
        });

        return;
      }
      if (loadType === 0) {
        const newArr = this.deDuplication(unhandledList, list);
        unhandledList.push(...newArr);
      } else {
        unhandledList = list;
      }
      this.messageStatus.unread.pageNum = data.currentPage;
      this.latestTs = data.latestTs;
      this.loading = false;
      this.setState({
        uid: data.uid,
        unhandledList,
        loading: false,
        isInitLoading: false,
        todoCount: data.totalCount,
      });
    });
  };

  loadHandledList = (loadType = 0) => {
    if (this.loading && this.state.handleType === 1) {
      return;
    }

    this.loading = true;

    let params = {
      pageNum: this.messageStatus.read.pageNum + 1,
      pageSize: this.messageStatus.read.pageSize,
    };


    let ngis = getSign({
      params,
    });

    get(api.API_NOTIFICATIONS_READ, {
      params,
      headers: {
        wsgsig: ngis,
      },
    }).then((data = {}) => {
      if (!this.isOpen) {
        return;
      }

      const list = data.items || [];

      if (!list.length) {
        this.messageStatus.read.loadedEnd = true;
        this.loading = false;
        this.setState({
          loading: false,
          isInitLoading: false,
        });

        return;
      }

      let { handledList } = this.state;

      if (loadType === 0) {
        handledList.push(...list);
      } else {
        handledList = list;
      }

      this.messageStatus.read.pageNum = data.currentPage;
      this.loading = false;
      this.setState({
        uid: data.uid,
        handledList,
        loading: false,
        isInitLoading: false,
      });
    });
  };
  /**
   * @param loadType {number} 加载类型
   * 0: 添加列表
   * 1: 覆盖更新列表
   */

  loadList = (loadType = 0) => {
    const { handleType } = this.state;

    if (handleType === 0) {
      this.loadUnhandledList(loadType);
    } else {
      this.loadHandledList(loadType);
    }
  };

  handleClickOutside = () => this.setState({
    isOpen: false,
  });

  // 点击标记已读
  onClickUnhandled = (item, index, e) => {
    e.stopPropagation();
    if (item.noticeType === 'Todo' || item.read) {
      return;
    }

    // 消息中心查看氢文档@通知时发送埋点
    const isDidocAtNotice = (item.messageType === 'DocumentAtNotice' || item.messageType === 'CommentAt') && item.mergeFields && item.mergeFields.docType === collaborativeDocumentTypes.didoc;

    const params = {
      messageType: item.messageType,
    };

    patch(
      api.API_NOTIFICATION_SIGN_READ.replace(':notificationId', item.id),
      params,
    ).then(() => {
      let { unhandledList, todoCount } = this.state;
      unhandledList[index].read = true;
      todoCount -= 1;
      unhandledList.splice(index, 1)
      this.setState({
        unhandledList,
        todoCount,
      });
    });
  };
  // 全部标记已读

  onClickAllAsRead() {
    if (this.state.unhandledList.length === 0) {
      return;
    }

    patch(api.API_NOTIFICATION_SIGN_ALL_READ.replace(':latestTs', this.latestTs), {
      headers: {
        'X-App-Id': 2,
      },
    }).then(() => {
      this.clear();
      this.loadUnhandledList(1, false);
    });
  }

  handleApplyToTeam = (id, accept, index) => {
    const status = accept ? 2 : 1;
    let { unhandledList: unhandledListArr, todoCount: todoCountNumber } = this.state;
    put(`${api.TEAM_APPLY_APPROVE.replace(':applyId', id)}?status=${status}`).then(() => {
      todoCountNumber -= 1;
      unhandledListArr.splice(index, 1);
      this.setState({
        unhandledList: unhandledListArr,
        todoCount: todoCountNumber,
      });
    }).catch((err) => {
      message.error(err.message);
      // 1153 申请时空间已经被删除 1154 空间申请已经被处理 1155 当空间权限转交后，点击同意导致的权限错误
      const errorCodeArr = [1153, 1154, 1155];
      if (errorCodeArr.includes(err.errorCode)) {
        todoCountNumber -= 1;
        unhandledListArr.splice(index, 1);
        this.setState({
          unhandledList: unhandledListArr,
          todoCount: todoCountNumber,
        });
      }
    });
  };

  handleAccess = (id, accept, index) => {
    let { unhandledList: unhandledListArr, todoCount: todoCountNumber } = this.state;
    const { mergeFields } = unhandledListArr[index];
    patch(api.COOPERATION_APPLY, {
      application_id: id,
      write: mergeFields.write,
      download: mergeFields.download,
      approverType: mergeFields.approverType,
      accept,
    }).then(() => {
      todoCountNumber -= 1;
      unhandledListArr.splice(index, 1);
      this.setState({
        unhandledList: unhandledListArr,
        todoCount: todoCountNumber,
      });

    }).catch((err) => {
      message.error(err.message);
      // 201020: 文档申请已经被处理 201021: “协作文档所有者点击同意时，文档已经删除” 201022:用户已加入该协作文档 1114:用户已加入团队空间
      const errorCodeArr = [201020, 201021, 201022, 1114];
      if (errorCodeArr.includes(err.errorCode)) {
        todoCountNumber -= 1;
        unhandledListArr.splice(index, 1);
        this.setState({
          unhandledList: unhandledListArr,
          todoCount: todoCountNumber,
        });
      }
    });
  };

  approveCooperAtionApply = (item, accept, index) => {
    let { unhandledList: unhandledListArr, todoCount: todoCountNumber } = this.state;
    const { mergeFields } = unhandledListArr[index];
    post(api.APPROVE_COOPERATION_SPACE_APPLY, {
      reason: mergeFields?.approvalReason,
      isAgree: accept,
      nextTaskId: mergeFields?.nextTaskId,
      nextUserLdap: mergeFields?.approverLdap,
      spaceId: mergeFields?.spaceId,
      bizId: mergeFields?.bizId,
    }).then(() => {
      todoCountNumber -= 1;
      unhandledListArr.splice(index, 1);
      this.setState({
        unhandledList: unhandledListArr,
        todoCount: todoCountNumber,
      });
    }).catch((err) => {
      message.error(err.message);
      todoCountNumber -= 1;
      unhandledListArr.splice(index, 1);
      this.setState({
        unhandledList: unhandledListArr,
        todoCount: todoCountNumber,
      });
    })
  }


  // 个人所有权 变更
  approveCooperAtionDocApply = (item, accept, index) => {
    let { unhandledList: unhandledListArr, todoCount: todoCountNumber } = this.state;
    const { mergeFields } = unhandledListArr[index];
    // 区分是否为 个人还是空间
    post(api.APPROVE_COOPERATION_DOC_APPLY, {
      reason: mergeFields?.approvalReason,
      isAgree: accept,
      nextTaskId: mergeFields?.nextTaskId,
      nextUserLdap: mergeFields?.approverLdap,
      resourceId: mergeFields?.resourceId,
      bizId: mergeFields?.bizId,
    },{
      headers: {
        'X-App-Id': 2,
      },
    }).then(() => {
        todoCountNumber -= 1;
        unhandledListArr.splice(index, 1);
        this.setState({
          unhandledList: unhandledListArr,
          todoCount: todoCountNumber,
        });
      }).catch((err) => {
        message.error(err.message);
        todoCountNumber -= 1;
        unhandledListArr.splice(index, 1);
        this.setState({
          unhandledList: unhandledListArr,
          todoCount: todoCountNumber,
        });
      })
  }

  // 申请更高权限审批
  approveHighPermDocApply = (item, accept, index) => {
    let { unhandledList: unhandledListArr, todoCount: todoCountNumber } = this.state;
    const { mergeFields = {}} = unhandledListArr[index];

    post(api.APPROVE_HIGH_PERM_DOC_APPLY, {
      applyId: mergeFields?.applyId,
      status: accept ? 'Approve' : 'Reject',
    }, {
      headers: {
        'X-App-Id': 2,
      },
    }).then((res) => {
      const { code } = res;
      if (code === 200007) {
        message.error(intl.t('申请者已离职，该申请已失效'))
      } else if (code === 200008) {
        message.error(intl.t('你的文档管理员权限被收回，你无法审批该申请'))
      } else if (code === 200009) {
        message.error(intl.t('该用户已获得该权限，可前往文档详情查看'))
      } else if (code === 100003) {
        message.error(intl.t('已被其他管理员同意'))
      } else if (code === 100004) {
        message.error(intl.t('已被其他管理员拒绝'))
      } else if (code === 100005) {
        message.error(intl.t('没有权限审批'))
      } else if (code === 100006) {
        message.error(intl.t('申请已失效'))
      }
      todoCountNumber -= 1;
      unhandledListArr.splice(index, 1);
      this.setState({
        unhandledList: unhandledListArr,
        todoCount: todoCountNumber,
      });
    }).catch((err) => {
      const { errorCode, errorMessage } = err;
      if (errorCode === 300002 || (errorCode === 100001 && errorMessage === '资源不存在')) {
        message.error(intl.t('文档已被删除，该申请已失效'));
      } else {
        message.error(err.errorMessage);
      }
      todoCountNumber -= 1;
      unhandledListArr.splice(index, 1);
      this.setState({
        unhandledList: unhandledListArr,
        todoCount: todoCountNumber,
      });
    })
  }

  handleTodoBtn = (item, accept, index) => {
    let id = 0;

    switch (item.messageType) {
      case 'CooperationApplication':
        id = item.mergeFields.id;
        this.handleAccess(id, accept, index);
        break;

      case 'SpaceApplication':
        id = item.mergeFields.id;
        this.handleApplyToTeam(id, accept, index);
        break;
      case 'SpaceOwnerApplyAppliedMessage':
        this.approveCooperAtionApply(item, accept, index);
        break;
      case 'DocOwnerApplyAppliedMessage':
        id = item.mergeFields.id;
        this.approveCooperAtionDocApply(id, accept, index);
        break;
      case 'DocApplicationPermCreateToApproverMessage':
        id = item.mergeFields.id;
        this.approveHighPermDocApply(item, accept, index);
        break;
      default:
    }
  };

  clear() {
    this.loading = false;
    this.messageStatus = {
      unread: {
        pageNum: -1,
        pageSize: PAGE_SIZE,
        loadedEnd: false,
      },

      read: {
        pageNum: -1,
        pageSize: PAGE_SIZE,
        loadedEnd: false,
      },
    };

    this.latestTs = new Date().getTime();
  }

  changeTab(handleType) {
    this.clear();
    this.setState(
      {
        handleType,
        isInitLoading: true,
        unhandledList: [],
        handledList: [],
      },

      () => {
        this.loadList(1);
      },
    );
  }

  onMouseEnter() {
    window.__OmegaEvent('ep_messagecenter_en', '', {
      platform: 'new',
    });
    this.isOpen = true;
    this.clear();
    this.setState(
      {
        isOpen: true,
        isInitLoading: true,
      },

      () => {
        this.loadUnhandledList(0);
        this.loadHandledList(0);
      },
    );
  }

  onMouseLeave() {
    this.isOpen = false;
    this.clear();
    this.setState({
      isOpen: false,
      isInitLoading: false,
      handleType: 0,
      handledList: [],
      unhandledList: [],
    });
  }

  onPopoverLeave() {
    this.messageStatus = {
      unread: {
        pageNum: -1,
        pageSize: PAGE_SIZE,
        loadedEnd: false,
      },

      read: {
        pageNum: -1,
        pageSize: PAGE_SIZE,
        loadedEnd: false,
      },
    };
  }

  clickToDChat(name, e) {
    e.stopPropagation();
    if (name === 'Cooper') {
      e.preventDefault();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderEmpty() {
    return (
      <div className='notify-list-body-empty'>
        <div className='notify-list-body-empty-box'>
          <div className='notify-list-body-empty-icon' />
          <div>{intl.t('暂无消息')}</div>
        </div>
      </div>
    );
  }

  renderFirstLoading() {
    return (
      <div className='notify-list-body-loading'>{this.renderLoading()}</div>
    );
  }

  renderLoading() {
    return (
      <li className='notify-list-loading'>
        {/* <img src={require('@images/loading.svg')} /> */}
        <span>{intl.t('加载中...')}</span>
      </li>
    );
  }

  renderListBody(list, uid) {
    function doBySelf(acceptedBy) {
      return !acceptedBy || acceptedBy === uid;
    }

    const { tenantId, globalOutsideChain } = this.props;

    return (
      <div
        className='notify-list-body-box'
        ref={(ref) => {
          this.ListItems = ref;
        }}
      >
        {list.map((v, k) => {
          const itemClassNames = classNames({
            'notify-list-item': true,
            unread: !v.read,
          });
          // const href = handleUrl(v);

          return (
            <div
              key={v.id + k}
              className={itemClassNames}>
              <div
                className='notify-list-item-box'
                onClick={(e) => {
                  this.onClickUnhandled(v, k, e);
                  // if (v.messageType !== 'SpaceDeleted') window.open(href);
                }}
              >
                <div className='notify-list-item-main'>
                  <a
                    className='notify-list-item-avatar'
                    href={`dchat://im/start_conversation?name=${v.from.ldap}&team_id=${v.from.tenantId ?? tenantId}`}
                    target='_blank'
                    onClick={this.clickToDChat.bind(
                      this,
                      v.from.name ? v.from.name.chineseName : '',
                    )}
                  >
                    <img
                      src={v.from.avatar}
                      alt={v.from.name ? v.from.name.chineseName : v.from.ldap}
                    />
                  </a>
                  <div className='notify-list-item-detail'>
                    <div className='notify-list-item-detail-info'>
                      <div className='notify-list-item-detail-info-left'>
                        <span className='notify-list-item-detail-name'>
                          <a
                            href={`dchat://im/start_conversation?name=${v.from.ldap}&team_id=${v.from.tenantId ?? tenantId}`}
                            onClick={this.clickToDChat.bind(
                              this,
                              v.from.name ? v.from.name.chineseName : '',
                            )}
                        >
                            {v.from.name ? v.from.name.chineseName : v.from.ldap}
                          </a>
                        </span>
                        {v.from.department && (
                          <span className='notify-list-item-detail-department'>（{v.from.department}）</span>
                        )}
                        {
                          v?.mergeFields?.showOuter && (
                            <Tag
                              type='out-yellow'
                              text={intl.t('外部')}/>
                          )
                        }
                      </div>
                      <span className='notify-list-item-detail-time'>
                        {dayjs(new Date(v.createdOn)).format('MM-DD HH:mm')}
                      </span>
                    </div>
                    {/* 新增站内信 */}
                    {v.messageType === 'DocumentOwnerApplyDeleteMessage'
                      && renderDocumentOwnerApplyDeleteMessage.bind(this, v, k)()}
                    {v.messageType === 'DocumentOwnerApplyNotFoundApproveMessage'
                      && renderCooperationApplyNoPerson.bind(this, v, k)()}
                    {/* 空间所有权 */}
                    {v.messageType === 'SpaceOwnerApplyDeleteMessage'
                      && renderSpaceOwnerApplyDeleteMessage.bind(this, v, k)()}
                    {v.messageType === 'CooperationApplication'
                      && renderCooperationApplication.bind(this, v, k)()}

                    {
                      v.messageType === 'DocApplicationPermCreateToOwnerMessage'
                      && renderCooperationApplicationAccepted.bind(this, v, 0)()
                    }

                    {v.messageType === 'CooperationApplicationNoApproverMessage'
                      && renderCooperationApplicationNoApproverMessage.bind(this, v, k)()}
                    {v.messageType === 'CooperationApplicationAccepted'
                      && renderCooperationApplicationAccepted.bind(this, v, k)()}
                    {v.messageType === 'CooperationApplicationRejected'
                      && renderCooperationApplicationRejected.bind(this, v, k)()}

                    {v.messageType === 'CooperationInvitation'
                      && renderCooperationInvitation.bind(this, v, k)()}
                    {/* 申请更高权限 */}
                    {v.messageType === 'DocApplicationPermCreateToApplicantMessage'
                      && renderDocPermApplySend.bind(this, v, k)()
                    }
                    {
                      v.messageType === 'ApplicationPermApproveMessage'
                      && renderDocPermApplyResult.bind(this, v, k, true)()
                    }
                    {
                      v.messageType === 'ApplicationPermRejectMessage'
                      && renderDocPermApplyResult.bind(this, v, k, false)()
                    }
                    {
                      v.messageType === 'ApplicationPermNoMemberApproveMessage'
                      && renderDocPermApplyNoPerson.bind(this, v, k, globalOutsideChain)()
                    }
                    {
                      v.messageType === 'DocApplicationPermCreateToApproverMessage'
                      && renderDocPermToApproved.bind(this, v, k)()
                    }
                    {/* 申请更高权限 */}
                    {
                      v.messageType === 'UserAssetTransferCompleteSuccessMessage'
                      && renderResignDeliver.bind(this, v, k)()
                    }
                    {v.messageType === 'SpaceApplication'
                      && renderTeamApplication.bind(this, v, k)()}
                    {v.messageType === 'SpaceApplicationAccepted'
                      && renderCooperationApplicationAccepted.bind(
                        this,
                        v,
                        k,
                        'team',
                      )()}

                    {v.messageType === 'SpaceQuotaAgreed'
                      && renderQuotaApplicationAccepted.bind(this, v, k)()}
                    {v.messageType === 'SpaceApplicationRejected'
                      && renderCooperationApplicationRejected.bind(
                        this,
                        v,
                        k,
                        'team',
                      )()}

                    {v.messageType === 'SpaceQuotaRejected'
                      && renderQuotaApplicationRejected.bind(this, v, k)()}

                    {v.messageType === 'DirectShareCreated' && (
                      <NoticeDirectShare
                        item={v}
                        onRead={(e) => this.onClickUnhandled(v, k, e)}
                      />
                    )}
                    {
                      v.messageType === 'SpaceOwnerApplyAppliedMessage' && renderSpaceOwnerApplication.bind(this, v, k)()
                    }
                    {
                      v.messageType === 'DocOwnerApplyAppliedMessage' && renderDocOwnerApplication.bind(this, v, k)()
                    }
                    {
                      v.messageType === 'DocOwnerApplyCompleteMessage'
                      && (<DocOwnerApplyCompleteMessage
                        item={v}
                        onRead={(e) => this.onClickUnhandled(v, k, e)}
                      />)
                    }
                    {
                      v.messageType === 'SpaceOwnerApplyCompleteMessage'
                      && (<SpaceOwnerApplyCompleteMessage
                        item={v}
                        onRead={(e) => this.onClickUnhandled(v, k, e)}
                      />)
                    }
                    {v.messageType === 'DocumentComment' && (
                      <NoticeDocumentComment
                        item={v}
                        onRead={(e) => this.onClickUnhandled(v, k, e)}
                      />
                    )}

                    {v.messageType === 'SpaceMemberInvited' && (
                      <NoticeTeamInvitation
                        item={v}
                        onRead={(e) => this.onClickUnhandled(v, k, e)}
                      />
                    )}

                    {v.messageType === 'SpaceTransferred' && (
                      <NoticeTeamDelivery
                        item={v}
                        onRead={(e) => this.onClickUnhandled(v, k, e)}
                      />
                    )}

                    {v.messageType === 'DocumentTransferred' && (
                      <NoticeDocumentTransferred
                        item={v}
                        onRead={(e) => this.onClickUnhandled(v, k, e)}
                      />
                    )}

                    {v.messageType === 'DocumentTransferredOwnerNotice' && (
                      <NoticeDocumentTransferredOwnerNotice
                        item={v}
                        onRead={(e) => this.onClickUnhandled(v, k, e)}
                      />
                    )}

                    {v.messageType === 'SpaceDeleted' && (
                      <NoticeTeamDeletion
                        item={v}
                      />
                    )}

                    {v.messageType === 'ThumbsUpCreated' && (
                      <NoticeDocumentThumbsup
                        item={v}
                        onRead={(e) => this.onClickUnhandled(v, k, e)}
                      />
                    )}
                    {
                      v.messageType === 'DocumentAtNotice' && (
                        <NoticeDocRefer
                          item={v}
                          onRead={(e) => this.onClickUnhandled(v, k, e)}
                        />
                      )
                    }
                    {
                      v.messageType === 'CommentAt' && (
                        <NoticeCommentRefer
                          item={v}
                          onRead={(e) => this.onClickUnhandled(v, k, e)}
                        />
                      )
                    }
                    {
                      v.messageType === 'PageAtNotice' && (
                        <PageAtNotice
                          item={v}
                          onRead={(e) => this.onClickUnhandled(v, k, e)}
                        />
                      )
                    }
                    {
                      v.messageType === 'SpaceCreatedApplyAgreeMessage' && (
                        <ApplyOutSpaceAgree
                          item={v}
                          onRead={(e) => this.onClickUnhandled(v, k, e)} />
                      )
                    }

                    {
                      v.messageType === 'SpaceCreatedApplyRejectMessage' && (
                        <ApplyOutSpaceReject
                          item={v}
                          onRead={(e) => this.onClickUnhandled(v, k, e)} />
                      )
                    }
                  </div>
                </div>
                {v.noticeType === 'Todo'
                  && renderTodo.bind(
                    this,
                    v,
                    k,
                    doBySelf(v.mergeFields.acceptedBy),
                  )()}
              </div>
            </div>
          );
        })}
        {this.state.loading && this.renderLoading()}
      </div>
    );
  }

  render() {
    const {
      isOpen,
      uid,
      isInitLoading,
      handleType,
      unhandledList,
      handledList,
      todoCount,
    } = this.state;
    const messageList = handleType === 0 ? unhandledList : handledList;
    const handledTabClassNames = classNames({
      'notify-list-header-ceil': true,
      'notify-list-header-handled': true,
      active: handleType === 1,
    });

    const allAsReadClassNames = classNames({
      'notify-list-header-allAsRead': true,
      hide: handleType === 1,
    });
    const content = (
      <div className={`cooper-notify ${this.props.type === 'small' ? 'notify-list-small' : ''}`}>
        <div className='notify-list'>
          <div className='notify-list-box'>
            <div className='notify-list-header'>
              <div
                className={`notify-list-header-ceil notify-list-header-unhandled${handleType === 0 ? ' active' : ''
                }`}
                onClick={this.changeTab.bind(this, 0)}
              >
                {intl.t('未处理')}
                {`（${formatNumber(todoCount)}）`}
              </div>
              <div
                className={handledTabClassNames}
                onClick={this.changeTab.bind(this, 1)}
              >
                {intl.t('已处理')}
              </div>
              <button
                className={allAsReadClassNames}
                onClick={this.onClickAllAsRead.bind(this)}
              >
                {intl.t('全部标记已读')}
              </button>
            </div>

            <div
              className='notify-list-body os-scrollbar'
              ref={(ref) => {
                this.ListBody = ref;
              }}
              onScroll={this.scrollToLoad}
            >
              {isInitLoading ? this.renderFirstLoading() : null}
              {!messageList.length && !isInitLoading
                ? this.renderEmpty()
                : null}
              {messageList.length
                ? this.renderListBody(messageList, uid)
                : null}
            </div>
          </div>
        </div>
      </div>
    )

    return (
      <Popover
        content={content}
        trigger={['click']}
        onVisibleChange={(visible) => {
          if (visible) {
            if (this.ListBody) {
              this.ListBody.scrollTop = 0
            }
            if (handleType === 0) {
              this.loadUnhandledList(1, false);
            } else if (handleType === 1) {
              this.loadHandledList(1);
            }
          } else {
            this.onPopoverLeave();
          }
        }}
        placement={this.props.type === 'small' ? 'right' : 'topLeft'}
        overlayClassName='cooper-notify-popover'
      >
        <div className={`cooper-notify-icon ${this.props.type === 'small' ? 'notify-list-small' : ''}`}>
          <i className="dk-iconfont dk-icon-zhanneixin" />
          {todoCount > 0 ? <span className='cooper-notify-count'>{formatNumber(todoCount)}</span> : null}
        </div>
      </Popover>
    );
  }
}

function mapStateToProps({ GlobalData, CooperIndex }) {
  const { unreadCount, tenantId } = GlobalData;
  const { globalOutsideChain } = CooperIndex;
  return { unreadCount, tenantId, globalOutsideChain };
}

function mapDispatchToProps({ GlobalData }) {
  const { getUnreadList } = GlobalData
  return {
    getUnreadList: () => getUnreadList(),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(onClickOutside(CooperNotify));
